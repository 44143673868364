
































































































































































































































































































import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import OrderService from "@/services/OrderService";
import Order, {OrderSelect, OrderType} from "@/models/Order";
import Options from "@/models/vue/Options";
import ClientService from "@/services/ClientService";
import Client from "@/models/Client";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import User from "@/models/User";
import UserService from "@/services/UserService";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import LangModule from "@/store/LangModule";
import OrderDetail from "@/models/OrderDetail";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue"
import {AuthorityName} from "@/models/Authority";
import Handler from "@/handlers/Handler";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import SessionTool from "@/services/tool/SessionTool";
import SessionMixin from "@/mixins/SessionMixin";
import CommercialService from "@/services/user/CommercialService";

@Component({components: {OrderStatusComponent}})
export default class MyOrdersView extends Mixins(SessionMixin) {

  lang: any = getModule(LangModule).lang
  loading: boolean = false
  page: number = 1
  pageCount: number = 0
  itemsPerPage: number = 10
  totalItems: number = 0
  search: string = ""
  clientSearch: string = ""
  selected: Order[] = []
  sessionModule: SessionModule = getModule(SessionModule)
  authority?: string = this.sessionModule.session.authorities[0].name

  get rateId() { return this.sessionModule.session.user.rateId }
  get sendButtonDisabled() { return this.selected.length == 0 }

  headers = [
    {align: "center", text: this.lang.date, value: "createdAt", width: "auto"},
    {align: "center", text: this.lang.createdBy, value: "createdBy", width: "auto"},
    {align: "center", text: this.lang.status, value: "status", width: "auto"},
    {align: "center", text: this.lang.actions, value: "actions", width: "auto"},
    {align: "center", text: "Xgest ID", value: "connectorId", width: "auto"},
  ]

  get headersWeb() {
    let headers = [
      {align: 'center', divider: true, width: '80px', text: "Id", value: 'id'},
      {align: 'center', divider: true, width: '150px', text: "Xgest ID", value: 'ref'},
      {align: 'center', divider: true, width: 'auto', text: this.lang.creationDate, value: 'createdAt'},
      {align: 'center', divider: true, width: '130px', text: this.lang.status, value: 'status',},
      (this.authority != AuthorityName.COMMERCIAL) ? {align: "center", divider: true, width: 'auto', text: this.lang.createdBy, value: "createdBy"} : {},
      {align: 'center', divider: true, width: 'auto', text: this.lang.client, value: 'client'},
      {align: 'center', divider: true, width: '100px', text: this.lang.total, value: 'totalAmount'},
      {align: 'center', divider: true, width: '100px', text: this.lang.edit, value: 'edit'},
    ]

    return headers
  }

  picker: string = ""
  dates: string[] = [this.picker]
  clients: Client[] = []
  clientsPage: number = 0
  // @ts-ignore
  orderType: OrderType | null = null

  orderTypes: OrderSelect[] = [
    {name: this.lang.draft, value: OrderType.DRAFT},
    {name: this.lang.onHold, value: OrderType.WAITING},
    {name: this.lang.sent, value: OrderType.SEND},
  ]

  //Filter models
  commercialId: number | null = null

  orderTypeFinish: OrderType = OrderType.FINISH
  orderTypeSend: OrderType = OrderType.SEND
  orderTypeDraft: OrderType = OrderType.DRAFT
  myOrders: boolean = false
  expand: boolean = true

  client: SingleItem<Nullable<Client>> = { item: null }
  commercial: SingleItem<Nullable<User>> = { item: null }
  clients2: MultipleItem<Client> = { items: [], totalItems: 0 }
  commercials2: MultipleItem<User> = { items: [], totalItems: 0 }
  orders2: MultipleItem<Order> = { items: [], totalItems: 0 }

  async created() {

    await this._catchElementsFromQueryParams()

    if (this.$route.query.all === "1") { this.myOrders = true }

    await this.getClients()
    await this.getCommercials()
    await this.refresh()
  }

  async exportToCsv() {
    await OrderService.getMyOrdersCsv(this, this.page - 1, this.itemsPerPage, this.commercialId, this.search, this.orderType, this.client.item?.id!!, this.dates[0], this.dates[1])
  }

  getTotalPrice(orderDetails: OrderDetail[]) {
    let totalPrice: number = 0
    orderDetails.forEach(orderDetail => {
      totalPrice += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity))
    })
    return totalPrice
  }

  isSelectable(item: Order) {
    return ((item.orderType !== OrderType.WAITING)) && (item.client) && (item.shippingAddress)
  }

  async sendOrders() {
    for (let item of this.selected) {
      if (item.id != null) {
        await OrderService.sendOrder(this, item.id)
        await this.refresh()
      }
    }
  }

  //Precio del distribuidor
  total(order: Order) {
    let total = 0
    const values = order.orderDetails!.map(e => Number(e.product!.rates![this.rateId! - 1].price) * Number(e.quantity))
    values.forEach(e => {
      total = total + e
    })
    return total
  }

  isDisableEdit(item: Order): boolean {
    return (item.orderType === this.orderTypeFinish) || (item.orderType === this.orderTypeSend)
  }

  async getClients() {
    await Handler.getItems(this, this.clients2, () =>
        ClientService.getClients2(
            0, 10, this.clientSearch,
            (this.isCommercial && !this.isDistributor)
        )
    )
  }

  async getCommercials() {
    await Handler.getItems(this, this.commercials2, () => UserService.getMyCommercials2(0, 100, false))
  }

  @Watch("options")
  async watchOptions() {

    if (this.isCommercial || this.myOrders) {
      await Handler.getItems(this, this.orders2, () =>
          OrderService.getMyOrdersByUser2(
              this.page - 1, this.itemsPerPage, this.sessionModule.session!.user!.id!, this.search, this.orderType, this.client.item?.id!!, this.dates[0], this.dates[1]
          ))
    } else {
      await Handler.getItems(this, this.orders2, () =>
          OrderService.getMyOrders2(
              this.page - 1, this.itemsPerPage, this.commercial.item?.id!!, this.search, this.orderType, this.client.item?.id!!, this.dates[0], this.dates[1]
          ))
    }

  }

  @Watch("client.item")
  onClientChanged() {
    if (this.client.item != null) { window.history.pushState(null, '', `?clientId=${ this.client.item!!.id }`) }
    else { window.history.pushState(null, '', '?clientId=') }
  }

  @Watch("commercial.item")
  onCommercialChanged() {
    if (this.commercial.item != null) { window.history.pushState(null, '', `?commercialId=${ this.commercial.item!!.id }`) }
    else { window.history.pushState(null, '', '?commercialId=') }
  }

  @Watch("all")
  watchAll() { this.watchOptions() }

  async refresh() {
    await this.watchOptions()
    this.selected = []
  }

  rowClick(order: Order) { this.$router.push('/order-details/' + order.id) }

  deleteOrder(id: number) {
    getModule(DialogModule).showDialog(new Dialog(
        this.lang.warning, this.lang.deleteOrderQuestion,
        () => OrderService.deleteOrder(this, id)
    ))
  }

  onClientClicked(order: Order) {
    if (order.client?.id) { this.$router.push("/clients/" + order.client.id) }
  }

  async _catchElementsFromQueryParams() {

    const clientId = Number(this.$route.query.clientId)
    const commercialId = Number(this.$route.query.commercialId)

    if (!isNaN(clientId)) {
      await Handler.getItem(this, this.client, () => ClientService.getClient2(Number(this.$route.query.clientId)))
    }


    if (!isNaN(commercialId)) {
      await Handler.getItem(this, this.commercial, () => CommercialService.getCommercial(Number(this.$route.query.commercialId)))
    }
  }

}
